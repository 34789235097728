/**
 * Desktop Responsive Styles
 * 
 * This file contains responsive styles for desktop and larger screens.
 * It follows the mobile-first approach with styles being applied at specified breakpoints.
 */

// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Container width constraints for better readability on large screens
@mixin container-constraints {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
  @media (min-width: $breakpoint-md) {
    max-width: 90%;
  }
  
  @media (min-width: $breakpoint-lg) {
    max-width: 1100px;
  }
  
  @media (min-width: $breakpoint-xl) {
    max-width: 1300px;
  }
}

// Apply responsive grid layout for ride cards
@mixin responsive-grid($columns, $gap) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
}

/* ============================
   Desktop Responsive Styles - Content Layout
   ============================ */

// Medium screens and up (tablets, small desktops)
@media (min-width: $breakpoint-md) {
  // Header adjustments
  ion-header {
    ion-toolbar ion-title {
      font-size: 22px;
    }
    
    ion-segment {
      max-width: 80%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  
  // Apply constrained container width
  .content-container, .desktop-container {
    @include container-constraints;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  // Card grid for medium screens - adaptive columns
  .ride-cards, 
  .available-rides,
  .current-rides-container,
  .history-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    width: 100%;
    padding: 16px;
  }
  
  // Fix height issues on larger screens
  .empty-rides-container,
  .empty-state {
    min-height: 500px;
    height: auto;
    padding: 64px 32px;
    
    .empty-rides-content {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  
  // Adjust button sizes on desktop
  ion-button {
    &.desktop-fixed-width {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  // Show text on buttons that hide on mobile
  .hide-sm {
    display: inline !important;
  }
  
  // Make cards more interactive on desktop
  .ride-card {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    width: 100% !important;
    margin: 0 !important;
    min-width: unset;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    }
    
    .ride-footer {
      margin-top: auto;
    }
    
    .ride-locations {
      flex-grow: 1;
    }
  }
  
  // Adjust ride sections for better spacing
  .rides-section {
    margin-bottom: 30px;
  }
  
  // Ensure all cards have consistent styling
  ion-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    ion-card-content {
      height: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  
  // Fix double border issue
  ion-card.ride-card {
    border: none;
    border-left: 4px solid var(--ion-color-primary);
  }

  // Fix scrolling behavior
  ion-content {
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;
  }
  
  // Style loading container for desktop
  .loading-container {
    ion-spinner {
      width: 64px;
      height: 64px;
    }
    
    p {
      font-size: 18px;
    }
  }
  
  // Fix ride cards wrapping and sizing
  .ride-cards {
    width: 100%;
    margin: 0;
  }
  
  // Adjust section title for desktop
  .section-title {
    font-size: 22px;
    margin-bottom: 16px;
    margin-top: 8px;
  }
  
  // Form improvements
  .desktop-form {
    max-width: 600px;
    margin: 0 auto;
    ion-item {
      --padding-start: 20px;
      --padding-end: 20px;
      --inner-padding-end: 16px;
    }
    ion-button {
      height: 52px;
      font-size: 16px;
      max-width: 400px;
      margin-left: auto !important;
      margin-right: auto !important;
      display: block;
    }
  }
  
  // Column-based form layout
  .form-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
  }
  
  // Desktop card improvements
  ion-card.desktop-card {
    margin: 20px;
    border-radius: 16px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    
    ion-card-header {
      padding: 20px 20px 12px;
    }
    
    ion-card-content {
      padding: 12px 20px 20px;
    }
    
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }
  }
  
  // Grid layout for card collections
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 16px;
  }
  
  // Desktop split-view layout
  .desktop-split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: start;
  }
  
  .desktop-sidebar {
    position: sticky;
    top: 16px;
  }
  
  // Calendar improvements
  .calendar-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    
    .calendar-item {
      flex: 1 1 calc(50% - 16px);
      min-width: 250px;
    }
  }
  
  // Desktop event grid
  .events-display-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }
  
  // Typography improvements
  h1 { font-size: 32px; margin-bottom: 20px; }
  h2 { font-size: 28px; margin-bottom: 18px; }
  h3 { font-size: 24px; margin-bottom: 16px; }
  h4 { font-size: 20px; margin-bottom: 14px; }
  
  .desktop-text-center {
    text-align: center;
  }
  
  .desktop-heading {
    margin-bottom: 32px;
    text-align: center;
  }
  
  // Utility classes
  .desktop-only {
    display: block;
  }
  
  .mobile-only {
    display: none;
  }
  
  .desktop-padding {
    padding: 24px;
  }
  
  .desktop-margin {
    margin: 24px;
  }
  
  .desktop-flex-row {
    display: flex;
    flex-direction: row;
  }
  
  .desktop-flex-column {
    display: flex;
    flex-direction: column;
  }
  
  // Split pane and menu styling
  ion-split-pane {
    --side-max-width: 280px;
    
    ion-menu {
      ion-toolbar {
        --color: white;
      }
    }
    
    // Adjust content padding with menu
    #main-content {
      ion-content {
        --padding-start: 24px;
        --padding-end: 24px;
      }
    }
  }
}

// Large screens (desktops)
@media (min-width: $breakpoint-lg) {
  // Increase segment width on larger screens
  ion-segment {
    max-width: 70% !important;
  }
  
  // Card grid for large screens - better adaptive columns
  .ride-cards, 
  .available-rides,
  .current-rides-container,
  .history-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  // Add more horizontal spacing
  .content-container, .desktop-container {
    padding-left: 24px;
    padding-right: 24px;
  }
  
  // Improve empty state visuals on large screens
  .empty-rides-container, 
  .empty-state {
    padding: 80px 48px;
    
    ion-icon {
      font-size: 80px;
    }
    
    h2, h3 {
      font-size: 28px;
    }
    
    p {
      font-size: 18px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  // Style enhancements for desktop users
  .ride-card {
    &:hover {
      .ride-actions {
        opacity: 1;
      }
    }
    
    .ride-actions {
      opacity: 0.7;
      transition: opacity 0.2s ease;
    }
  }
  
  // Add desktop navigation
  .desktop-nav {
    display: flex !important;
  }
  
  // Add desktop action bar
  .desktop-action-bar {
    display: flex;
    justify-content: center;
    padding: 16px;
    gap: 16px;
    background-color: rgba(var(--ion-color-light-rgb), 0.8);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
    margin: 0 auto 24px;
    max-width: 800px;
    
    ion-button {
      flex: 1;
      max-width: 200px;
      margin: 0 8px;
      
      ion-icon {
        margin-right: 8px;
      }
    }
  }
  
  // Menu and split pane improvements - wider for large screens
  ion-split-pane {
    --side-width: 280px;
    --side-max-width: 300px;
  }
  
  // Desktop header with elevated appearance
  .desktop-header {
    --background: rgba(255, 255, 255, 0.95);
    --border-color: transparent;
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

// Extra large screens
@media (min-width: $breakpoint-xl) {
  // Card grid for extra large screens - 4 columns
  .ride-cards, 
  .available-rides,
  .current-rides-container,
  .history-container {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
  }
  
  // Further increase horizontal padding
  .content-container, .desktop-container {
    padding-left: 32px;
    padding-right: 32px;
  }
  
  // Extra wide menu for very large screens
  ion-split-pane {
    --side-max-width: 320px;
  }
}

/* ============================
   Desktop Modal Improvements
   ============================ */
ion-modal.desktop-modal {
  --width: 80%;
  --max-width: 800px;
  --height: auto;
  --max-height: 80%;
  --border-radius: 16px;
  --box-shadow: 0 10px 25px rgba(0, 0, 0, 0.18);
  
  .modal-wrapper {
    border-radius: var(--border-radius);
  }
}

.calendar-import-modal {
  --width: 80%;
  --max-width: 900px;
  --height: auto;
  --max-height: 80%;
}

/* ============================
   Desktop Hover Effects
   ============================ */
@media (hover: hover) {
  ion-button:hover {
    opacity: 0.9;
  }
  
  ion-item.item-interactive:hover {
    --background: rgba(var(--ion-color-primary-rgb), 0.04);
  }
  
  .interactive-item:hover {
    background-color: rgba(var(--ion-color-primary-rgb), 0.06);
  }
  
  ion-fab-button:hover {
    --box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  ion-card.interactive:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .hover-highlight:hover {
    background-color: rgba(var(--ion-color-primary-rgb), 0.08);
  }
}

/* ============================
   Print Styles
   ============================ */
@media print {
  ion-header,
  ion-footer,
  ion-fab,
  .no-print {
    display: none !important;
  }
  
  ion-content {
    --padding: 0;
    margin: 0;
  }
  
  .ride-card {
    page-break-inside: avoid;
    border: 1px solid #ddd;
    box-shadow: none !important;
    margin: 16px 0;
  }
}