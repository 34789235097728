/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component.
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils - kept the most frequently used ones */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Force light mode and prevent dark mode */
:root {
  color-scheme: light;
}

/* Override Ionic dark mode detection */
@media (prefers-color-scheme: dark) {
  :root {
    --ion-color-primary: var(--ion-color-primary);
    --ion-color-secondary: var(--ion-color-secondary);
    /* Maintain light theme even in dark mode */
  }
}

/* Base styles */
body {
  background-color: var(--app-background-color);
  color: var(--ion-color-dark);
  font-family: var(--ion-font-family);
}

.ios {
  --ion-background-color: var(--app-background-color);
  --ion-background-color-rgb: 248, 249, 252;
  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: 34, 36, 40;
}

/* ============================
   Flex utility classes
   ============================ */
.flex { display: flex; }
.flex-column { 
  display: flex; 
  flex-direction: column; 
}
.flex-row { 
  display: flex; 
  flex-direction: row; 
}
.flex-center { 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.flex-between { 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
}
.flex-around { 
  display: flex; 
  justify-content: space-around; 
  align-items: center; 
}
.flex-grow { flex-grow: 1; }

/* ============================
   Typography
   ============================ */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: var(--ion-headline-font-weight);
  letter-spacing: -0.5px;
}
h1 { font-size: 28px; margin-bottom: 16px; }
h2 { font-size: 24px; margin-bottom: 14px; }
h3 { font-size: 20px; margin-bottom: 12px; }
h4 { font-size: 18px; margin-bottom: 10px; }
p {
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1.5;
}

/* ============================
   Ionic Component Overrides
   ============================ */
/* Card styles */
ion-card {
  border-radius: var(--ion-card-border-radius);
  margin: var(--ion-card-margin);
  box-shadow: var(--ion-card-box-shadow) !important;
  overflow: hidden;
  transition: transform var(--app-transition-duration-fast);
  
  &.interactive:active {
    transform: scale(0.98);
  }
}
ion-card-header {
  padding: 16px 16px 8px 16px !important;
}
ion-card-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--ion-color-dark) !important;
}
ion-card-subtitle {
  font-size: 14px !important;
  color: var(--ion-color-medium) !important;
}
ion-card-content {
  padding: 8px 16px 16px 16px !important;
}

/* Button styles */
ion-button {
  --border-radius: var(--ion-button-border-radius);
  --box-shadow: 0 4px 10px rgba(var(--ion-color-primary-rgb), 0.2);
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  transition: opacity var(--app-transition-duration-fast), transform var(--app-transition-duration-fast) !important;
  height: var(--ion-button-height) !important;
  
  &.button-solid {
    --box-shadow: 0 4px 10px rgba(var(--ion-color-primary-rgb), 0.3);
    --background: var(--ion-color-primary) !important;
    --color: white !important;
    
    &:hover {
      --box-shadow: 0 6px 15px rgba(var(--ion-color-primary-rgb), 0.4);
    }
    
    &.ion-color-secondary {
      --box-shadow: 0 4px 10px rgba(var(--ion-color-secondary-rgb), 0.3);
      --background: var(--ion-color-secondary) !important;
      --color: white !important;
      
      &:hover {
        --box-shadow: 0 6px 15px rgba(var(--ion-color-secondary-rgb), 0.4);
      }
    }
  }
  
  &.button-clear, &.button-outline {
    font-weight: 600 !important;
    --opacity: 1 !important;
    --color-hover: var(--ion-color-primary-shade) !important;
    
    &.ion-color-secondary {
      --color-hover: var(--ion-color-secondary-shade) !important;
    }
  }
  
  &:active {
    --opacity: 0.8 !important;
    transform: scale(0.98) !important;
  }
  
  /* Icon-only buttons */
  &[fill="clear"][size="small"],
  &[fill="clear"].icon-only {
    --padding-start: 6px;
    --padding-end: 6px;
    --background-hover: rgba(var(--ion-color-primary-rgb), 0.08) !important;
    --background-activated: rgba(var(--ion-color-primary-rgb), 0.12) !important;
  }
}

/* Form styles */
ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --background: transparent !important;
  --border-color: rgba(0, 0, 0, 0.05);
  --highlight-color-focused: var(--ion-color-primary) !important;
  --highlight-height: 2px !important;
  margin-bottom: 8px !important;
  
  &.item-interactive {
    --ripple-color: var(--ion-color-primary-tint);
  }
}
ion-label {
  font-weight: 500 !important;
  font-size: 16px !important;
}
ion-input, ion-textarea, ion-select {
  --padding-start: 16px;
  --padding-end: 16px;
  border-radius: 8px;
  font-size: 16px !important;
  
  &.has-focus {
    --background: rgba(var(--ion-color-primary-rgb), 0.05);
  }
}
ion-toggle {
  --background: #ddd !important;
  --background-checked: var(--ion-color-primary) !important;
  --handle-background: #fff !important;
  --handle-background-checked: #fff !important;
}

/* List styles */
ion-list {
  background: transparent;
  padding: 8px 0;
}
ion-list-header {
  padding-top: 16px !important;
  padding-bottom: 8px !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  color: var(--ion-color-medium) !important;
  font-weight: 600 !important;
}

/* Segment styles */
ion-segment {
  background: white !important;
  padding: 4px !important;
  border-radius: 8px !important;
  margin: 16px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05) !important;
}
ion-segment-button {
  --color: var(--ion-color-medium) !important;
  --color-checked: var(--ion-color-primary) !important;
  --indicator-color: var(--ion-color-primary) !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

/* Toolbar styles */
ion-toolbar {
  --background: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary-contrast) !important;
  --border-color: transparent !important;
  --padding-top: 8px !important;
  --padding-bottom: 8px !important;
  --padding-start: 16px !important;
  --padding-end: 16px !important;
}
ion-header ion-toolbar:first-of-type {
  padding-top: env(safe-area-inset-top);
}
ion-back-button {
  --color: white !important;
  --icon-font-size: 24px !important;
  --icon-font-weight: bold !important;
  --background-hover: rgba(255, 255, 255, 0.1) !important;
  --background-focused: rgba(255, 255, 255, 0.2) !important;
  --background-hover-opacity: 0.3 !important;
  --border-radius: 50% !important;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  --icon-margin-top: 2px !important;
  --icon-margin-bottom: 2px !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
}
ion-toolbar[color="secondary"] ion-back-button,
ion-toolbar[color="medium"] ion-back-button,
ion-toolbar[color="light"] ion-back-button {
  --color: var(--ion-color-dark) !important;
  text-shadow: none !important;
}

/* UI Elements */
ion-avatar {
  --border-radius: 50% !important;
  border: 2px solid white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}
ion-badge {
  padding: 4px 8px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
ion-icon {
  --ionicon-stroke-width: 2.5px !important;
}

/* ============================
   Animation utilities
   ============================ */
.animate-fade-in {
  animation: fadeIn var(--ion-animation-duration-normal) ease-in-out;
}
.animate-slide-up {
  animation: slideUp var(--ion-animation-duration-normal) ease-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* ============================
   Modal styles
   ============================ */
ion-modal {
  --max-width: 400px;
  --border-radius: 16px;
  --backdrop-opacity: 0.3;
  --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  
  ion-header::after {
    display: none;
  }
  
  @media (max-width: 768px) {
    --width: 100%;
    --height: 100%;
    --border-radius: 16px 16px 0 0;
  }
  
  ion-content {
    --padding-top: 16px;
    --padding-bottom: 16px;
  }
}

/* ============================
   Safety components
   ============================ */
ion-fab-button.safety-fab {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-danger-contrast);
  --box-shadow: 0 4px 16px rgba(var(--ion-color-danger-rgb), 0.2);
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --border-radius: 50%;
  
  ion-icon {
    font-size: 24px;
  }
}
.ios .safety-fab {
  padding-bottom: env(safe-area-inset-bottom);
}
.safety-page {
  ion-header.header-md::after {
    display: none;
  }
  
  ion-toolbar {
    --min-height: 56px;
    --padding-top: env(safe-area-inset-top);
    --padding-start: 8px;
    --padding-end: 8px;
  }
}

/* Hide tab bar on specific pages - mobile only */
@media (max-width: 767px) {
  app-pickup ion-tab-bar,
  app-drop-off ion-tab-bar {
    display: none !important;
  }
}

/* ============================
   Common UI patterns
   ============================ */
.error-message {
  color: var(--ion-color-danger);
  font-size: 12px;
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  
  ion-icon {
    font-size: 64px;
    margin-bottom: 16px;
    opacity: 0.7;
  }
  
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--ion-color-dark);
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: var(--ion-color-medium);
    margin-bottom: 24px;
  }
}
.verification-status {
  display: flex;
  align-items: center;
  
  ion-icon {
    margin-right: 6px;
    
    &.verified {
      color: var(--ion-color-success);
    }
    
    &.pending {
      color: var(--ion-color-warning);
    }
    
    &.not-verified {
      color: var(--ion-color-medium);
    }
  }
  
  span {
    font-size: 14px;
  }
}

/* ============================
   Ride component styles
   ============================ */
.ride-card {
  margin: 16px 8px;
  border-radius: var(--ion-card-border-radius);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:active {
    transform: scale(0.98);
  }
  
  .ride-header {
    padding: 16px;
    background-color: rgba(var(--ion-color-light-rgb), 0.7);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ride-locations {
    padding: 16px;
    
    .location {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      
      ion-icon {
        margin-right: 12px;
        font-size: 20px;
      }
      
      p {
        margin: 0;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    
    .location-connector {
      width: 2px;
      height: 24px;
      background-color: rgba(var(--ion-color-medium-rgb), 0.3);
      margin-left: 10px;
      margin-bottom: 12px;
    }
  }
  
  .ride-details {
    padding: 0 16px 16px;
    display: flex;
    flex-wrap: wrap;
    
    .detail {
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-bottom: 8px;
      
      ion-icon {
        margin-right: 6px;
        color: var(--ion-color-medium);
      }
      
      span {
        font-size: 14px;
        color: var(--ion-color-medium);
      }
    }
  }
  
  .ride-actions {
    padding: 12px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    gap: 10px;
    
    ion-button {
      margin: 0;
      flex: 1;
    }
  }
}

/* Fix for ride-details positioning issues */
ion-app ion-router-outlet app-ride-details {
  position: absolute !important;
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 100 !important; /* Ensures it's above other elements */
}

/* Fix for ride-details page positioning */
app-ride-details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  contain: layout size style;
  z-index: 0;
  overflow: hidden;
}

ion-app.ion-page app-ride-details ion-header,
ion-app.ion-page app-ride-details ion-content,
ion-app.ion-page app-ride-details ion-footer {
  position: relative;
  contain: layout size style;
}

/* ============================
   Platform-specific styles
   ============================ */
.ios {
  ion-header ion-toolbar:first-of-type {
    --padding-top: calc(env(safe-area-inset-top) + 8px);
  }
  
  ion-footer {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* ============================
   Page transitions
   ============================ */
ion-router-outlet.page-transition ion-page.ion-page-invisible {
  opacity: 0;
}
.ios, .md {
  ion-nav, ion-router-outlet {
    .ion-page {
      &.page-transition-forward {
        animation: pageTransitionForward 0.3s ease-in-out;
      }
      
      &.page-transition-backward {
        animation: pageTransitionBackward 0.3s ease-in-out;
      }
    }
  }
}
@keyframes pageTransitionForward {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
@keyframes pageTransitionBackward {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

/* ============================
   Dialog styles
   ============================ */
.confirmation-dialog-modal {
  --width: 90%;
  --max-width: 400px;
  --height: auto;
  --border-radius: 12px;
  --backdrop-opacity: 0.5;
}

/* ============================
   Tab bar improvements
   ============================ */
ion-tab-bar.custom-tab-bar {
  background: var(--ion-color-light) !important;
  
  ion-tab-button {
    &:active {
      background-color: rgba(var(--ion-color-primary-rgb), 0.08);
    }
  }
}

/* Import desktop responsive styles */
@import "./theme/desktop-responsive.scss";

/* ============================
   Calendar import related styles
   ============================ */
.calendar-list {
  margin: 16px 0;
}
.calendar-item {
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  
  &.selected {
    border-color: var(--ion-color-primary);
    background-color: rgba(var(--ion-color-primary-rgb), 0.08);
  }
}
.calendar-icon {
  font-size: 20px;
  margin-right: 12px;
}
