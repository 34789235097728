// Ionic Variables and Theming
// http://ionicframework.com/docs/theming/

/** 
 * Core Ionic CSS Variables 
 * These variables define the base colors and styles for the app
 */
:root {
  /** Color palette - primary colors **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #12b886;
  --ion-color-secondary-rgb: 18, 184, 134;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #10a276;
  --ion-color-secondary-tint: #2ac092;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** Color palette - feedback colors **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 240, 65, 65;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** Color palette - neutral colors **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Special use case colors **/
  --ion-color-favorite: #ff5596;  // Warm pink for favorites/likes
  --ion-color-favorite-rgb: 255, 85, 150;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #e04b84;
  --ion-color-favorite-tint: #ff66a1;

  --ion-color-trusted: #00c2d8;  // Teal for trusted network elements
  --ion-color-trusted-rgb: 0, 194, 216;
  --ion-color-trusted-contrast: #ffffff;
  --ion-color-trusted-contrast-rgb: 255, 255, 255;
  --ion-color-trusted-shade: #00abbe;
  --ion-color-trusted-tint: #1ac8dc;

  /** 
   * App-specific variables 
   * These variables are used throughout the app for consistent theming
   */
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, 
                     Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --ion-headline-font-weight: 600;

  /** App structure **/
  --app-background-color: #f8f9fa;
  --app-text-color: #333333;
  --app-text-color-secondary: #666666;
  --app-border-color: rgba(0, 0, 0, 0.08);
  --app-padding: 16px;
  --app-margin: 16px;

  /** Card styles **/
  --app-card-background: #ffffff;
  --ion-card-border-radius: 12px;
  --ion-card-margin: 12px;
  --ion-card-padding: 16px;
  --ion-card-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  /** Buttons and inputs **/
  --ion-button-border-radius: 8px;
  --ion-button-height: 44px;
  --ion-item-border-radius: 8px;

  /** Animation durations **/
  --app-transition-duration-fast: 0.2s;
  --app-transition-duration: 0.3s;
  --app-transition-timing: ease;
  --ion-animation-duration-fast: 0.2s;
  --ion-animation-duration-normal: 0.3s;
  --ion-animation-duration-slow: 0.5s;

  /** 
   * Responsive Breakpoints
   * These variables define the breakpoints for responsive design
   */
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  
  // Container max-widths
  --container-sm: 540px;
  --container-md: 720px;
  --container-lg: 960px;
  --container-xl: 1140px;
  --container-xxl: 1400px;
}

/* ============================
   Base Element Styles
   ============================ */
body {
  --ion-background-color: var(--app-background-color);
  --ion-text-color: var(--app-text-color);
  font-family: var(--ion-font-family);
}

// Card styles
ion-card {
  border-radius: var(--ion-card-border-radius);
  box-shadow: var(--ion-card-box-shadow);
  margin: var(--ion-card-margin);
  padding: var(--ion-card-padding);
  background: var(--app-card-background);
  overflow: hidden;
}

// Button styles
ion-button {
  --border-radius: var(--ion-button-border-radius);
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: none;
  transition: all var(--app-transition-duration) var(--app-transition-timing);
}

// Input styles
ion-input, ion-textarea, ion-select {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --border-radius: var(--ion-item-border-radius);
  --background: var(--ion-color-light);
  margin-bottom: 12px;
}

// Item styles
ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --inner-padding-end: 0;
  --background: transparent;
  --border-color: var(--app-border-color);
}

// Segment styles
ion-segment {
  --background: transparent;
  
  ion-segment-button {
    --border-radius: 8px;
    --padding-top: 6px;
    --padding-bottom: 6px;
    --margin-top: 8px;
    --margin-bottom: 8px;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 0;
  }
}

// Badge styles
ion-badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-weight: 500;
}

/* ============================
   Light theme
   ============================ */
.theme-light {
  --app-background-color: #f8f9fa;
  --app-card-background: #ffffff;
  --app-card-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  --app-text-color: #333333;
  --app-text-color-secondary: #666666;
  --app-border-color: rgba(0, 0, 0, 0.08);
}

/* ============================
   Dark theme
   ============================ */
.theme-dark {
  /* System colors */
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;
  
  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;
  
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;
  
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  
  /* Inverted light and dark */
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  
  /* App theme specific colors */
  --app-background-color: #121212;
  --app-card-background: #1e1e1e;
  --app-card-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  --app-text-color: #f4f4f4;
  --app-text-color-secondary: #b0b0b0;
  --app-border-color: rgba(255, 255, 255, 0.08);
}

/* ============================
   Apple-inspired theme
   ============================ */
.theme-apple {
  /* Colors */
  --ion-color-primary: #0071e3; /* Apple's blue */
  --ion-color-primary-rgb: 0, 113, 227;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0062c4;
  --ion-color-primary-tint: #1a7fe6;
  
  --ion-color-secondary: #5e5ce6; /* Apple's purple */
  --ion-color-secondary-rgb: 94, 92, 230;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5351ca;
  --ion-color-secondary-tint: #6e6ce9;
  
  --ion-color-tertiary: #ff2d55; /* Apple's pink */
  --ion-color-tertiary-rgb: 255, 45, 85;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #e0274b;
  --ion-color-tertiary-tint: #ff4267;
  
  --ion-color-success: #34c759; /* Apple's green */
  --ion-color-success-rgb: 52, 199, 89;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2eaf4e;
  --ion-color-success-tint: #48cd6a;
  
  --ion-color-warning: #ff9500; /* Apple's orange */
  --ion-color-warning-rgb: 255, 149, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e08300;
  --ion-color-warning-tint: #ffa01a;
  
  --ion-color-danger: #ff3b30; /* Apple's red */
  --ion-color-danger-rgb: 255, 59, 48;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e0342a;
  --ion-color-danger-tint: #ff4f45;
  
  /* App theme overrides */
  --app-background-color: #f5f5f7;
  --app-background-image: url('/assets/themes/apple-bg.svg');
  --app-card-background: #ffffff;
  --app-card-border-radius: 16px;
  --ion-card-border-radius: 16px;
  --app-card-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  --ion-card-box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  --app-text-color: #1d1d1f;
  --app-text-color-secondary: #86868b;
  --app-border-color: rgba(0, 0, 0, 0.05);
  --app-padding: 20px;
  --app-margin: 20px;
  --app-transition-duration: 0.4s;
  --app-transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Element overrides */
  --ion-button-border-radius: 20px;
  
  /* Specific element styles */
  ion-content {
    --background: var(--app-background-color) var(--app-background-image) no-repeat center center / cover fixed;
  }
  
  ion-toolbar {
    --background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}

/* ============================
   System theme (uses prefers-color-scheme)
   ============================ */
@media (prefers-color-scheme: dark) {
  body:not(.theme-light):not(.theme-apple):not(.theme-dark) {
    /* Import dark theme variables for system dark mode */
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;
    
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    
    /* App theme specific colors */
    --app-background-color: #121212;
    --app-card-background: #1e1e1e;
    --app-card-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    --app-text-color: #f4f4f4;
    --app-text-color-secondary: #b0b0b0;
    --app-border-color: rgba(255, 255, 255, 0.08);
  }
}